import React from 'react';

import MainContainer from './components/MainContainer';
import CenterLogo from './components/CenterLogo';
import Body from './components/Body';

function App() {

  return (
    <MainContainer>
      <CenterLogo />
      <Body />
    </MainContainer>
  );
}

export default App;
