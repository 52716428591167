import styled from 'styled-components';

export const Title = styled.code`
  margin-top: 5%;
  font-size: 48px;

  @media only screen and (max-width: 600px) {
    font-size: 36px;
  }

  @media (max-height: 650px) {
    margin-top: 0;
    font-size: 36px;
  }
`;