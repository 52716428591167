import React from 'react';
import * as S from './CenterLogo.styled';

// // https://developer.mozilla.org/en-US/docs/Web/CSS/transform

const CenterLogo = () => {

  return ( 
    <S.Title>
      <span style={{ color: "#c885e4" }}>while</span>(<span style={{ color: "#d1a371" }}>true</span>)&#123;<br />
      &emsp;<span style={{ color: "#85cbf5" }}>drink</span>(<i className="fa fa-coffee fa-1x"></i>) <br />
      {/* todo: rotate coffee icons. https://fontawesome.com/search?q=coffee&o=r */}
      &#125;
   </S.Title>);
};

export default CenterLogo;