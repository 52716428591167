import React from 'react';
import * as S from './Body.styled';
import { getNextMeeting } from '../utils/nextMeeting';

const CenterLogo = () => {
  return ( 
    <S.Subtitle>
      <p>Join Broad Ripple Code and Coffee every other Wednesday morning at 7:30am Eastern time.</p>
      <p>Next Meeting: <code>{getNextMeeting()}</code> (<a href="https://meet.google.com/rey-edcj-myh">virtual</a>)</p>
      <p>Talk with us on <a href="https://www.indyhackers.org/">Indy Hackers</a></p>
   </S.Subtitle>);
};

export default CenterLogo;