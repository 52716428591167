import skipJson from '../assets/skip.json';

const oneDay = 1000 * 60 * 60 * 24;
const twoWeeks = oneDay * 14;
const originalDate = new Date("2022-11-30T08:30:00.000-05:00");

function getNextWednesday(date = new Date()) {
  const dateCopy = new Date(date.getTime());

  const nextWednesday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 3) % 7 || 7),
    ),
  );

  return nextWednesday;
}

export const getNextMeeting = (now = new Date(), skip = skipJson) => {
  let next;

  const isWednesday = now.getDay() === 3;
  const difference = (now.valueOf() - originalDate.valueOf()) % twoWeeks;

  if (twoWeeks - difference < oneDay && isWednesday) next = now;
  else if (difference < twoWeeks / 2) next = getNextWednesday(getNextWednesday(now));
  else next = getNextWednesday(now);

  let output = next.toDateString();

  if (skip[output]) {
    next = new Date(now.valueOf() + oneDay);
    output = getNextMeeting(next, skip); 
  }

  return output;
}