import styled from 'styled-components';

export const Subtitle = styled.div`
  margin-top: 5%;
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  max-width: 400px;

  @media only screen and (max-width: 600px) {
    margin: 15% 16px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-height: 650px) {
    margin-top: 0;
  }
`;