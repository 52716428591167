import styled from 'styled-components';

export const Body = styled.div`
  background-image: ${props => props.backgroundImage};
  height: 100%;
`;

export const Margin = styled.div`
  padding: 16px;
  height: calc(100% - 32px);
`;

export const Main = styled.div`
  background-color: #282c34;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  @media (max-height: 650px) {
    flex-direction: row;
    gap: 36px;
  }
`;